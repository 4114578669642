import { PNSet, PNFullSet, PN_BonusNotWinnable } from "./PNSets";

const Award = (perfect, near, betSize, step) => {
	const perfectMatchWeight = 1;
	const nearMatchWeight = 0.25;
	const PNMinMultiplier = 0.15;
	const PNMaxMultiplier = 1;
	const globalPNumber = 116.0;
	const globalNNumber = 116.0;

  const perfectMatchesWeight = globalPNumber * perfectMatchWeight;
  const nearMatchesWeight = globalNNumber * nearMatchWeight;
  const PNTotalWeight = perfectMatchesWeight + nearMatchesWeight;
  const avgMultiplierStep = (PNMaxMultiplier - PNMinMultiplier) / PNTotalWeight;


  const isBonusable = (perfect, near) => {
	let isBonus = false;
	for(let i = 0; i < PN_BonusNotWinnable.length; i++) {
	  if(perfect === PN_BonusNotWinnable[i][0] && near === PN_BonusNotWinnable[i][1]) {
		isBonus = true;
		break;
	  }
	}
	return isBonus;
  }

  const getScore = (perfect, near, step) => {
	let score = 0;

	if (step === 1) {
	  for (let i = 0; i < PNFullSet.length; i++) {
		if (PNFullSet[i][0] === perfect && PNFullSet[i][1] === near) {
		  score = perfect * perfectMatchWeight + near * nearMatchWeight;
		}
	  }
	}

	for (let i = 0; i < PNSet.length; i++) {
	  if (PNSet[i][0] === perfect && PNSet[i][1] === near) {
		score = perfect * perfectMatchWeight + near * nearMatchWeight;
	  }
	}

	return score;
  };

  const isCombinationWinnable = (perfect, near) => {
	let winnable = false;

	for (let i = 0; i < PNSet.length; i++) {
	  if (PNSet[i][0] === perfect && PNSet[i][1] === near) {
		winnable = true;
		break;
	  }
	}
	return winnable;
  };

  const getPNSum = (perfectMatches, nearMatches) => {
	const result = [0, 0];

	for (const item in PNSet) {
	  result[0] += PNSet[item][0];
	  result[1] += PNSet[item][1];

	  if (PNSet[item][0] === perfectMatches && PNSet[item][1] === nearMatches) {
		break;
	  }
	}

	return result;
  };

  const calculateMultiplier = (perfectMatches, nearMatches) => {
	const PNSum = getPNSum(perfectMatches, nearMatches);
	const perfectSum = PNSum[0];
	const nearSum = PNSum[1];

	const currentPMW = (perfectSum - 2) * perfectMatchWeight;
	const currentNMW = nearSum * nearMatchWeight;
	const currentMultiplier =
	  (currentPMW + currentNMW) * avgMultiplierStep + PNMinMultiplier;

	return currentMultiplier;
  };

  const score = getScore(perfect, near, step);
  const multiplier = calculateMultiplier(perfect, near);
  const isWinnable = isCombinationWinnable(perfect, near);
  const isBonus = isBonusable(perfect, near);
  const award = !isWinnable && !isBonus ? 0 : betSize * (perfect + near) * multiplier;

  return {
	score: score,
	award: award,
	winnable: isWinnable,
	bonusable: isBonus,
  };
};

export default Award;
