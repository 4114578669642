const ArrayShuffle = (arr) => {
  const newArr = arr.slice();
  for (let i = newArr.length - 1; i > 0; i--) {
	const rand = Math.floor(Math.random() * (i + 1));
	[newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
};

export default ArrayShuffle;
