import { useState, useEffect } from "react";
import useSound from 'use-sound';

import 'animate.css';
import "./components/App.css";

import Dashboard from "./components/Dashboard";
import Template from "./components/Template";
import Controls from "./components/Controls";
import MultiplierView from "./components/MultiplierView";
import TemplateAnimation from "./components/TemplateAnimation";
import BonusSpinner from "./components/BonusSpinner";

import { CopyTile, CopyTiles } from "./helpers/CopyTiles";
import { generateDashboard, generateTemplate } from "./helpers/Generator";
import BestMove from "./helpers/BestMove";
import Bonus from "./helpers/Bonus";
import BonusTypes from "./helpers/BonusTypes";
import Multiplier from "./helpers/Multiplier";
import UnderDashboard from "./components/UnderDashboard";

import shuffleSound from "../src/sounds/shuffle.mp3";
import winSound from "../src/sounds/win.wav";
import endGame from "../src/sounds/end.wav";
import bonusSound from "../src/sounds/bonus.mp3";


import {
  onBetSizeUp,
  onBetSizeDown,
} from "./helpers/Game";

import {
  newMakeAllNearToPerfectBonus,
  newSingleTileShuffleBonuses,
  newMoveNearToPerfectBonus,
  newSwapNearMatchesBonus,
  newMovePerfectToNearBonus,
  freeSpinBonus,
  newNoBonus,
} from "./helpers/GameBonuses";
import GamificationMultiplier from "./helpers/GamificationMultiplier";

const App = () => {
  const betStep = 0.05;

  const [shuffleSoundEffect] = useSound(shuffleSound);
  const [winSoundEffect] = useSound(winSound);
  const [endEffect] = useSound(endGame);
  const [bonusEffect] = useSound(bonusSound);

  const [state, setState] = useState({
    dashboardTiles: [],
    templateTiles: [],
    position: { x: 0, y: 0 },
    credits: 10000,
    currentWin: 0,
    multiplierBonus: 1,
    betSize: 2,
    step: 1,
    started: false,
    roundStarted: 0,
    showBonusShuffle: 0,
    possibleBonuses: [],
    multiplier: 1,
    showMultiplier: false,
	showMagnifier: false,
	enableAudio: true
  });

   const toggleMagnifier = () => {
		setState((prev) => {
			return {
				...prev,
				showMagnifier: !prev.showMagnifier
			};
		});
	};

	const toggleSound = () => {
		setState((prev) => {
			return {
				...prev,
				enableAudio: !prev.enableAudio
			};
		});
	};

  useEffect(() => {
    if(state.roundStarted > 0) {
      // @todo
    }
  }, [state.roundStarted]);

  useEffect(() => {}, [state.dashboardTiles]);
  useEffect(() => {}, [state.templateTiles]);

  const reloadNewGame = () => {
    console.clear();

    let dashboardTiles = [];
    let templateTiles = [];
    let initialTemplate = [];
    let hasStarted = false;
    
    const position = {
      x: 0,
      y: 0
    };
    const appSteps = [];
    let preBonuses = [];
    let postBonuses = [];
    let currentWin = 0;
    let currentStep = 1;
    let currentCredits = state.credits;
    let multiplier = 1;

    const addAppStep = (stepType, args) => {
      appSteps.push({
        stepType: stepType,
        args: args
      });
    };

    const setCurrentMultiplier = (multiplierNumber) => {
      multiplier = multiplierNumber;
    };

    const setHasStarted = (started) => {
      hasStarted = started;
    };

    const setCurrentCredits = (credits) => {
      currentCredits = credits;
    };

    const setCurrentDashboardTiles = (generatedDashboardTiles) => {
      dashboardTiles = generatedDashboardTiles;
    };

    const setCurrentTemplateTiles = (generatedTemplateTiles) => {
      templateTiles = generatedTemplateTiles;
    };

    const moveTemplate = (x, y) => {
      position.x = x;
      position.y = y;
    };

    const setCurrentStep = (step) => {
      currentStep = 0;
    };

    const markTemplateTiles = (perfect, near, templateTilesParam) => {
      const newTemplateTiles = [];
      const perfectIds = perfect.map((item) => {
        return item.index;
      });
      const nearIds = near.map((item) => {
        return item.index;
      });

      for(let x = 0; x < templateTilesParam.length; x++) {
        newTemplateTiles[x] = CopyTile(templateTilesParam[x]);

        if (perfectIds.indexOf(newTemplateTiles[x].index) !== -1) {
          newTemplateTiles[x].perfect = true;
        }
        else if (nearIds.indexOf(templateTiles[x].index) !== -1) {
          newTemplateTiles[x].near = true;
        }
		else {
			newTemplateTiles[x].near = false;
			newTemplateTiles[x].perfect = false;
		}
      }
      
      return newTemplateTiles;
    };
  
    const collectTemplateTiles = (perfect, near, templateTilesParam) => {
      const newTemplateTiles = [];
      const perfectIds = perfect.map((item) => {
        return item.index;
      });
      const nearIds = near.map((item) => {
        return item.index;
      });

      for(let x = 0; x < templateTilesParam.length; x++) {
        newTemplateTiles[x] = CopyTile(templateTilesParam[x]);

        if (perfectIds.indexOf(newTemplateTiles[x].index) !== -1) {
          newTemplateTiles[x].collected = true;
          newTemplateTiles[x].perfect = false;
          newTemplateTiles[x].near = false;
        }
        else if (nearIds.indexOf(templateTiles[x].index) !== -1) {
          newTemplateTiles[x].collected = true;
          newTemplateTiles[x].perfect = false;
          newTemplateTiles[x].near = false;
        }
		else {
			newTemplateTiles[x].perfect = false;
			newTemplateTiles[x].near = false;
		}
      }
      
      return newTemplateTiles;
    };
  
    const setCurrentWin = (winAmount) => {
      currentWin += winAmount;
    };

    const generatedDashboardArray = generateDashboard();
    const generatedTemplateArray = generateTemplate(generatedDashboardArray);

	console.log(generatedDashboardArray);
	console.log(generatedTemplateArray);

    Multiplier(generatedDashboardArray, generatedTemplateArray, position.x, position.y, (multiplier) => {
      setCurrentMultiplier(multiplier);

      setCurrentCredits(state.credits - state.betSize);
      addAppStep("setCurrentCredits", {
        credits: currentCredits,
        currentWin: 0,
        timeout: 0
      });
  
      setCurrentDashboardTiles(generatedDashboardArray);
      addAppStep("setCurrentDashboardTiles", {
        dashboard: dashboardTiles,
        timeout: 0
      });
  
      initialTemplate = generatedTemplateArray;
      setCurrentTemplateTiles(generatedTemplateArray);
      addAppStep("setCurrentTemplateTiles", {
        template: templateTiles,
        timeout: 0
      });
  
      setHasStarted(true);
      addAppStep("setHasStarted", {
        started: true,
        timeout: 3500
      });
    });

    

    const finalizeRound = () => {
      setCurrentCredits(currentCredits + (currentWin * multiplier));
      addAppStep("setCurrentCredits", {
        credits: currentCredits,
        currentWin: currentWin * multiplier,
        timeout: 0
      });

	  if(multiplier > 1) {
		addAppStep("setCurrentMultiplier", {
			multiplier: multiplier,
			currentWin: currentWin,
			timeout: 1000
		});
		}

      setCurrentStep(1);
      addAppStep("setCurrentStep", {
        currentStep: 1,
        timeout: 0
      });
    };

    const runPreBonuses = () => {
      if(preBonuses.length === 0) {
        runPostBonuses();
        return;
      }

	  let gamificationMultiplier = 1;

      const bestMove = BestMove(
        dashboardTiles,
        templateTiles,
        state.betSize,
        currentStep,
		position.x,
		position.y
      );

      addAppStep("showBonusShuffle", {
        bonus: preBonuses[0],
        timeout: 6000
      });

	  let beforeMatches = null;
	  let afterMatches = null;

	  if(bestMove !== null) {
		beforeMatches = [bestMove.perfect.length, bestMove.near.length];
	  }

	  if(preBonuses[0].bonus != BonusTypes.NO_BONUS.index && bestMove != null && bestMove.winnable) {
		const prevTemplateTiles = CopyTiles(templateTiles);

		templateTiles = collectTemplateTiles(bestMove.perfect, bestMove.near, templateTiles);
		addAppStep("collectTemplateTiles", {
			templateTiles: templateTiles,
			timeout: 1000
		});

		setCurrentWin(bestMove.award);
		addAppStep("setCurrentWin", {
			currentWin: currentWin,
			timeout: 0
		});

		setCurrentTemplateTiles(prevTemplateTiles);
		addAppStep("setCurrentTemplateTiles", {
			template: prevTemplateTiles,
			timeout: 1000
		});

	  }

      let bonusResult;

      switch(preBonuses[0].bonus) {

        case BonusTypes.MOVE_NEAR_TO_BECOME_PERFECT.index:
          bonusResult = newMoveNearToPerfectBonus(preBonuses, position.x, position.y, dashboardTiles, templateTiles);
        break;

        case BonusTypes.SINGLE_TILE_SHUFFLE.index:
		  console.log(dashboardTiles);
          bonusResult = newSingleTileShuffleBonuses(preBonuses, dashboardTiles, templateTiles, position.x, position.y, state.betSize);
        break;

        case BonusTypes.MOVE_PERFECT_TO_NEAR.index:
          bonusResult = newMovePerfectToNearBonus(preBonuses, templateTiles);
        break;

        case BonusTypes.SWAP_NEAR_TILES.index:
          bonusResult = newSwapNearMatchesBonus(preBonuses, position.x, position.y, dashboardTiles, templateTiles);
        break;

        case BonusTypes.MAKE_ALL_NEAR_TO_PERFECT.index:
          bonusResult = newMakeAllNearToPerfectBonus(preBonuses, position.x, position.y, dashboardTiles);
        break;

        case BonusTypes.NO_BONUS.index:
          bonusResult = newNoBonus(preBonuses, templateTiles);
        break;
      }

	  let bestMatchBonus = null;
	  let hasToMove = false;

	  if(preBonuses[0].bonus == BonusTypes.NO_BONUS.index && postBonuses.length === 0) {
		finalizeRound();
		return;
	  }

	  const currentPositionBonuses = [
		BonusTypes.MOVE_NEAR_TO_BECOME_PERFECT.index,
		BonusTypes.MOVE_PERFECT_TO_NEAR.index,
		BonusTypes.SWAP_NEAR_TILES.index,
		BonusTypes.MAKE_ALL_NEAR_TO_PERFECT.index,
		BonusTypes.NO_BONUS.index
	  ];

      if(currentPositionBonuses.indexOf(preBonuses[0].bonus) !== -1) {
		bestMatchBonus = BestMove(
			dashboardTiles,
			bonusResult.templateTiles,
			state.betSize,
			currentStep,
			position.x,
			position.y
		);
	  }
	  else {
		bestMatchBonus = BestMove(
			dashboardTiles,
			bonusResult.templateTiles,
			state.betSize,
			currentStep
		);
		hasToMove = true;
	  }

	  preBonuses = bonusResult.preBonuses;

	  setCurrentTemplateTiles(bonusResult.templateTiles);
		addAppStep("setCurrentTemplateTiles", {
			template: bonusResult.templateTiles,
			timeout: 1000
		});

		if (bestMatchBonus !== null) {
			afterMatches = [bestMatchBonus.perfect.length, bestMatchBonus.near.length];
			gamificationMultiplier = GamificationMultiplier(beforeMatches, afterMatches);

			if(hasToMove) {
				moveTemplate(bestMatchBonus.x, bestMatchBonus.y);
				addAppStep("moveTemplate", {
					x: bestMatchBonus.x,
					y: bestMatchBonus.y,
					timeout: 1000
				});
			}

			templateTiles = markTemplateTiles(bestMatchBonus.perfect, bestMatchBonus.near, templateTiles);
			addAppStep("markTemplateTiles", {
			templateTiles: templateTiles,
			timeout: 1000
			});

			if (bestMatchBonus.winnable) {
			templateTiles = collectTemplateTiles(bestMatchBonus.perfect, bestMatchBonus.near, templateTiles);
			addAppStep("collectTemplateTiles", {
				templateTiles: templateTiles,
				timeout: 1000
			});

			setCurrentWin(bestMatchBonus.award * gamificationMultiplier);
			addAppStep("setCurrentWin", {
				currentWin: currentWin,
				timeout: 0
			});
		}
      }

      runPreBonuses();
    };

    const runPostBonuses = () => {
      if(postBonuses.length === 0) {
        finalizeRound();
        return;
      }

	  let beforeMatches = null;
	  let gamificationMultiplier = 1;

      addAppStep("showBonusShuffle", {
        bonus: postBonuses[0],
        timeout: 6000
      });

      let bonusResult;

      switch(postBonuses[0].bonus) {
        case BonusTypes.FREE_SPIN.index:
          bonusResult = freeSpinBonus(postBonuses, dashboardTiles);
        break;
      }

      postBonuses = bonusResult.postBonuses;

      const bestMatchBonus = BestMove(
        dashboardTiles,
        bonusResult.templateTiles,
        state.betSize,
        currentStep
      );

	  setCurrentTemplateTiles(bonusResult.templateTiles);
        addAppStep("setCurrentTemplateTiles", {
          template: bonusResult.templateTiles,
          timeout: 0
        });

        setHasStarted(true);
        addAppStep("setHasStarted", {
          started: true,
          timeout: 3500
        });

      if (bestMatchBonus !== null) {

		beforeMatches = [bestMatchBonus.perfect.length, bestMatchBonus.near.length];
		gamificationMultiplier = GamificationMultiplier(beforeMatches, null);

        moveTemplate(bestMatchBonus.x, bestMatchBonus.y);
        addAppStep("moveTemplate", {
          x: bestMatchBonus.x,
          y: bestMatchBonus.y,
          timeout: 1000
        });

        templateTiles = markTemplateTiles(bestMatchBonus.perfect, bestMatchBonus.near, bonusResult.templateTiles);            
        addAppStep("markTemplateTiles", {
          templateTiles: templateTiles,
          timeout: 1000
        });

        if(bestMatchBonus.winnable) {
          templateTiles = collectTemplateTiles(bestMatchBonus.perfect, bestMatchBonus.near, bonusResult.templateTiles);
          addAppStep("collectTemplateTiles", {
            templateTiles: templateTiles,
            timeout: 1000
          });

          setCurrentWin(bestMatchBonus.award * gamificationMultiplier);
          addAppStep("setCurrentWin", {
            currentWin: currentWin,
            timeout: 0
          });
        }
      }

      runPostBonuses();
    };

    const checkForNextWin = () => {
      const bestMove = BestMove(
        dashboardTiles,
        templateTiles,
        state.betSize,
        currentStep
      );

      if (currentStep === 1) {
        const bonuses = Bonus(
          bestMove.perfect.length,
          bestMove.near.length
        );

        preBonuses = bonuses.preBonuses;
        postBonuses = bonuses.postBonuses;

		console.log("PRE BONUSES");
		console.log(preBonuses);

		console.log("POST BONUSES");
		console.log(postBonuses);
      }

      if ((bestMove === null || bestMove.winnable === false) && preBonuses.length === 0 && postBonuses.length === 0) {
        finalizeRound();
        return;
      }
      else if (bestMove !== null) {
        
        setCurrentStep(currentStep + 1);
        addAppStep("setCurrentStep", {
          currentStep: currentStep,
          timeout: 1000
        });

		moveTemplate(bestMove.x, bestMove.y);
		addAppStep("moveTemplate", {
			x: position.x,
			y: position.y,
			timeout: 1000
		});

		templateTiles = markTemplateTiles(bestMove.perfect, bestMove.near, templateTiles);
		addAppStep("markTemplateTiles", {
			templateTiles: templateTiles,
			timeout: 1000
		});

		if (bestMove.winnable) {
          templateTiles = collectTemplateTiles(bestMove.perfect, bestMove.near, templateTiles);
          addAppStep("collectTemplateTiles", {
            templateTiles: templateTiles,
            timeout: 1000
          });

          setCurrentWin(bestMove.award);
          addAppStep("setCurrentWin", {
            currentWin: currentWin,
            timeout: 0
          });

          checkForNextWin();
          return;
        }
        else {
          runPreBonuses();
          return;
        }
      }
      else if (bestMove === null || bestMove.winnable === false) {
        runPreBonuses();
      }
    };

    checkForNextWin();
	console.log(appSteps); 
	
	processSteps(appSteps);
  };

  const processSteps = (steps) => {
    const currentSteps = [...steps];

    if (currentSteps.length > 0) {
      const currentStepToExecute = currentSteps[0];

      switch(currentStepToExecute.stepType) {
        case "setCurrentCredits":
          setState((prev) => {
            return {
              ...prev,
              credits: currentStepToExecute.args.credits,
              currentWin: currentStepToExecute.args.currentWin
            };
          });
          break;

        case "setCurrentDashboardTiles":
          setState((prev) => {
            return {
              ...prev,
              dashboardTiles: currentStepToExecute.args.dashboard
            };
          });
          break;

        case "setCurrentTemplateTiles":
          setState((prev) => {
            return {
              ...prev,
              templateTiles: currentStepToExecute.args.template
            };
          });
        break;

        case "setHasStarted":
		  if(state.enableAudio) {
          	shuffleSoundEffect();
		  }
          setState((prev) => {
            return {
              ...prev,
              started: currentStepToExecute.args.started,
              roundStarted: prev.roundStarted + 1
            };
          });
        break;

        case "moveTemplate":
          setState((prev) => {
            return {
              ...prev,
              position: {
                x: currentStepToExecute.args.x,
                y: currentStepToExecute.args.y
              }
            };
          });
        break;

        case "markTemplateTiles":
          setState((prev) => {
            return {
              ...prev,
              templateTiles: currentStepToExecute.args.templateTiles
            };
          });
        break;

        case "collectTemplateTiles":
			if(state.enableAudio) {
			winSoundEffect();
			}
          setState((prev) => {
            return {
              ...prev,
              templateTiles: currentStepToExecute.args.templateTiles
            };
          });
        break;

        case "setCurrentWin":
          setState((prev) => {
            return {
              ...prev,
              currentWin: currentStepToExecute.args.currentWin
            };
          });
        break;
        
        case "showBonusShuffle":
			if(state.enableAudio) {
          		bonusEffect();
			}
          setState((prev) => {
            return {
              ...prev,
              showBonusShuffle: prev.showBonusShuffle + 1,
              possibleBonuses: currentStepToExecute.args.bonus
            };
          });
        break;

        case "setCurrentStep":
          if(currentStepToExecute.args.currentStep === 1) {
			if(state.enableAudio) {
            	endEffect();
			}
          }
          setState((prev) => {
            return {
              ...prev,
              step: currentStepToExecute.args.currentStep
            };
          });
        break;

        case "setCurrentMultiplier":
          if(currentStepToExecute.args.multiplier > 1 && currentStepToExecute.args.currentWin > 0) {
            setState((prev) => {
              return {
                ...prev,
                multiplier: currentStepToExecute.args.multiplier,
                showMultiplier: true
              };
            });
          }
        break;
      }

      currentSteps.shift();

	  if(currentSteps.length === 0) {
		return;
	  }

      setTimeout(() => {
        processSteps(currentSteps);
      }, currentStepToExecute.args.timeout);

    }
  };


  return (
    <>
      <Dashboard animate={true} visible={state.started} tiles={state.dashboardTiles}>
        <UnderDashboard>
          <Dashboard animate={false} scaled={true} x={state.position.x} y={state.position.y} visible={state.started && state.showMagnifier} tiles={state.dashboardTiles}></Dashboard>
        </UnderDashboard>
        <Template
          x={state.position.x}
          y={state.position.y}
          tiles={state.templateTiles}
        ></Template>
        <TemplateAnimation 
          started={state.roundStarted}
          x={state.position.x}
          y={state.position.y} 
          dashboardTiles={state.dashboardTiles}
          templateTiles={state.templateTiles} 
        />
		<BonusSpinner possibleBonuses={state.possibleBonuses} showBonusShuffle={state.showBonusShuffle} />
      </Dashboard>
      <Controls
	    enableAudio={state.enableAudio}
	  	toggleSound={toggleSound}
	    toggleMagnifier={toggleMagnifier}
        step={state.step}
        started={state.started}
        currentWin={state.currentWin}
        afterWinChanged={state.afterWinChanged}
        credits={state.credits}
        betSize={state.betSize}
        onBetSizeUp={() => { onBetSizeUp(state, betStep, setState); }}
        onBetSizeDown={() => { onBetSizeDown(state, betStep, setState); }}
        onShuffle={reloadNewGame}
      />
      {state.showMultiplier &&
        <MultiplierView multiplier={state.multiplier} />
      }
    </>
  );
  
};

export default App;
