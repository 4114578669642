import BonusChance from "./BonusChance";
import BonusTypes from "./BonusTypes";

const PN_Set = [
  {
	perfect: 1,
	near: 3,
	bonuses: [
		{bonus: 1, range: [0, 99], times: 1},
		{bonus: 3, range: [100, 199], times: 1},
		{bonus: 0, range: [200, 599], times: 1}
	],
	range: [0, 599]
  },
  {
	perfect: 3,
	near: 1,
	bonuses: [
		{bonus: 2, range: [0, 99], times: 1},
		{bonus: 1, range: [100, 199], times: 1},
		{bonus: 3, range: [200, 299], times: 2}
	],
	range: [0, 299]
  },
  {
	perfect: 1,
	near: 4,
	bonuses: [
		{bonus: 4, range: [0, 99], times: 1},
		{bonus: 3, range: [100, 199], times: 1},
		{bonus: 0, range: [200, 299], times: 1}
	],
	range: [0, 299]
  },
  {
	perfect: 4,
	near: 1,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 2, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 5,
	near: 0,
	bonuses: [
		{bonus: 1, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 1,
	near: 5,
	bonuses: [
		{bonus: 1, range: [0, 49], times: 4},
		{bonus: 4, range: [50, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 3,
	near: 3,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 1, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 4,
	near: 2,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 5,
	near: 1,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 1, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 6,
	near: 0,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 0,
	near: 7,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 1,
	near: 6,
	bonuses: [
		{bonus: 1, range: [-1, -1], times: 10},
		{bonus: 4, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 2,
	near: 5,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 1, range: [0, 100], times: 3}
	],
	range: [0, 100]
  },
  {
	perfect: 3,
	near: 4,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 1, range: [0, 100], times: 5}
	],
	range: [0, 100]
  },
  {
	perfect: 4,
	near: 3,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 5,
	near: 2,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 6,
	near: 1,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 2, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 7,
	near: 0,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 0,
	near: 8,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 1,
	near: 7,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 4, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 2,
	near: 6,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 3,
	near: 5,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 4,
	near: 4,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 5,
	near: 3,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 3}
	],
	range: [0, 100]
  },
  {
	perfect: 6,
	near: 2,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
  {
	perfect: 7,
	near: 1,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 2, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 8,
	near: 0,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 3, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 0,
	near: 9,
	bonuses: [
		{bonus: 0, range: [0, 49], times: 1},
		{bonus: 6, range: [50, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 1,
	near: 8,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 4, range: [0, 100], times: 1}
	],
	range: [0, 100]
  },
  {
	perfect: 2,
	near: 7,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 3}
	],
	range: [0, 100]
  },
  {
	perfect: 3,
	near: 6,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 3}
	],
	range: [0, 100]
  },
  {
	perfect: 4,
	near: 5,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 3}
	],
	range: [0, 100]
  },
  {
	perfect: 5,
	near: 4,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 4}
	],
	range: [0, 100]
  },
  {
	perfect: 6,
	near: 3,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 3}
	],
	range: [0, 100]
  },
  {
	perfect: 7,
	near: 2,
	bonuses: [
		{bonus: 0, range: [-1, -1], times: 1},
		{bonus: 5, range: [0, 100], times: 2}
	],
	range: [0, 100]
  },
];

const Bonus = (perfect, near) => {
  const preBonuses = [];
  const postBonuses = [];

  for (let i = 0; i < PN_Set.length; i++) {
	if (PN_Set[i].perfect === perfect && PN_Set[i].near === near) {
	  const bonusChance = BonusChance(PN_Set[i]);

	  if (bonusChance.bonus === BonusTypes.FREE_SPIN.index) {
		postBonuses.push(bonusChance);
	  } else {		  
		preBonuses.push(bonusChance);
	  }

	}
  }

  return {
	preBonuses: preBonuses,
	postBonuses: postBonuses,
  };
};

export default Bonus;
