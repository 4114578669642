import RandomInt from "./RandomInt";

const BonusChance = (bonus) => {

	let bonusWon = {
	  bonus: null,
	  times: 0,
	  possibleBonuses: []
	};
  
	const rangeFrom = bonus.range[0];
	const rangeTo = bonus.range[1];

	const randNumber = RandomInt(rangeFrom, rangeTo);
	const bonuses = bonus.bonuses;
	let chosenBonus = null;
	let chosenTimes = 1;
	let possibleBonuses = [];

	for(let i = 0; i < bonuses.length; i++) {
		let currBonus = bonuses[i];
		let currBonusRangeFrom = currBonus.range[0];
		let currBonusRangeTo = currBonus.range[1];

		if(randNumber >= currBonusRangeFrom && randNumber <= currBonusRangeTo) {
			chosenBonus = currBonus.bonus;
			chosenTimes = currBonus.times;
		}

		possibleBonuses.push(currBonus.bonus);
	}

	bonusWon = {
		bonus: chosenBonus,
		times: chosenTimes,
		possibleBonuses: possibleBonuses
	};

	return bonusWon;
};

export default BonusChance;
