import RandomInt from "./RandomInt";

const GamificationMultiplier = (bonusCombination, winningCombination) => {
	let maxGamificationMultiplier = 1;

	if(bonusCombination == null && winningCombination == null) {
		return maxGamificationMultiplier;
	}

	if(bonusCombination == [3,1] && winningCombination == [4,0]) {
		const rand = RandomInt(1,2);

		if(rand == 1) {
			maxGamificationMultiplier = 5;
		}
	}

	if(bonusCombination == [4,1] && winningCombination == [5,0]) {
		maxGamificationMultiplier = 5;
	}

	if(bonusCombination == [1,5] && winningCombination == [0,6]) {
		const rand = RandomInt(1,2);

		if(rand == 1) {
			maxGamificationMultiplier = 5;
		}
	}

	if(bonusCombination == [1,6] && winningCombination == [0,7]) {
		maxGamificationMultiplier = 5;
	}

	if(bonusCombination == [4,0]) {
		maxGamificationMultiplier = 6;
	}

	if(bonusCombination == [3,2]) {
		maxGamificationMultiplier = 3;
	}

	if(bonusCombination == [0,6]) {
		maxGamificationMultiplier = 4;
	}

	if(bonusCombination == [2,4]) {
		maxGamificationMultiplier = 3;
	}

	if(bonusCombination == [3,3]) {
		maxGamificationMultiplier = 4;
	}

	if(bonusCombination == [4,2]) {
		maxGamificationMultiplier = 10;
	}

	if(bonusCombination == [0,7]) {
		maxGamificationMultiplier = 7;
	}

	if(bonusCombination == [2,5]) {
		maxGamificationMultiplier = 12;
	}

	if(bonusCombination == [4,3]) {
		const rand = RandomInt(1,2);

		if(rand == 1) {
			maxGamificationMultiplier = 100;
		}
	}

	if(bonusCombination == [3,5]) {
		maxGamificationMultiplier = 500;
	}

	if(bonusCombination == [1,8]) {
		maxGamificationMultiplier = 1000;
	}

	return maxGamificationMultiplier;
};

export default GamificationMultiplier;