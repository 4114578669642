const PNFullSet = [
	[0, 0],
	[0, 1],
	[1, 0],
	[0, 2],
	[1, 1],
	[2, 0],
	[0, 3],
	[1, 2],
	[2, 1],
	[3, 0],
	[0, 4],
	[1, 3],
	[2, 2],
	[3, 1],
	[4, 0],
	[0, 5],
	[1, 4],
	[2, 3],
	[3, 2],
	[4, 1],
	[5, 0],
	[0, 6],
	[1, 5],
	[2, 4],
	[3, 3],
	[4, 2],
	[5, 1],
	[6, 0],
	[0, 7],
	[1, 6],
	[2, 5],
	[3, 4],
	[4, 3],
	[5, 2],
	[6, 1],
	[7, 0],
	[0, 8],
	[1, 7],
	[2, 6],
	[3, 5],
	[4, 4],
	[5, 3],
	[6, 2],
	[7, 1],
	[8, 0],
	[0, 9],
	[1, 8],
	[2, 7],
	[3, 6],
	[4, 5],
	[5, 4],
	[6, 3],
	[7, 2],
	[8, 1],
	// [9, 0],
	// [1, 2],
	// [2, 1],
	// [1, 3],
	// [3, 1],
	// [1, 4],
	// [4, 1],
	// [5, 0],
	// [1, 5],
	// [3, 3],
	// [4, 2],
	// [5, 1],
	// [6, 0],
	// [0, 7],
	// [1, 6],
	// [2, 5],
	// [3, 4],
	// [4, 3],
	// [5, 2],
	// [6, 1],
	// [7, 0],
	// [0, 8],
	// [1, 7],
	// [2, 6],
	// [3, 5],
	// [4, 4],
	// [5, 3],
	// [6, 2],
	// [7, 1],
	// [8, 0],
	// [0, 9],
	// [1, 8],
	// [2, 7],
	// [3, 6],
	// [4, 5],
	// [5, 4],
	// [6, 3],
	// [7, 2],
	// [8, 1],
];

const PNSet = [
	[0, 4],
	[2, 2],
	[4, 0],
	[0, 5],
	[2, 3],
	[3, 2],
	[5, 0],
	[0, 6],
	[2, 4],
	[3, 3],
	[4, 2],
	[6, 0],
	[0, 7],
	[2, 5],
	[3, 4],
	[4, 3],
	[5, 2],
	[7, 0],
	[0, 8],
	[2, 6],
	[3, 5],
	[4, 4],
	[5, 3],
	[6, 2],
	[8, 0],
	[0, 9],
	[2, 7],
	[3, 6],
	[4, 5],
	[5, 4],
	[6, 3],
	[7, 2],
	[9, 0],
];

const PN_BonusNotWinnable = [
	[2, 0],
	[0, 3],
	[3, 0],
	[1, 2],
	[2, 1],
	[1, 3],
	[3, 1],
	[1, 4],
	[4, 1],
	[1, 5],
	[5, 1],
	[1, 6],
	[6, 1],
	[1, 7],
	[7, 1],
	[1, 8],
	[2, 0],
	[0, 3],
	[3, 0],
];

export { PNFullSet, PNSet, PN_BonusNotWinnable };